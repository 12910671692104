
import Accordion from 'components/dist/atoms/Accordion';
import Avatar from 'components/dist/atoms/Avatar';
import Badge from 'components/dist/atoms/Badge';
import Checkbox from 'components/dist/atoms/Checkbox';
import Icon from 'components/dist/atoms/Icon';
import Label from 'components/dist/atoms/Label';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import { forwardRef } from 'react';
import { VentureChangeLevel } from 'src/backend';

import { VenturesDataChangedMapperProps } from './ventures-data-changed-mapper.types';

interface FieldsBlockBaseProps {
    items: VenturesDataChangedMapperProps["items"]
    value: string[]
    onValueChange: (value: string[]) => void
    onScroll?: (event: React.UIEvent<HTMLDivElement>) => void
}

interface FieldsBlockSourceProps {
    type: "SOURCE"
    onSelect: (ids: string[]) => void
    selectedIds: string[]
}

interface FieldsBlockTargetProps {
    type: "TARGET"
}

type FieldsBlockProps = FieldsBlockBaseProps & (FieldsBlockSourceProps | FieldsBlockTargetProps)

export const VenturesDataChangedMapperFields = forwardRef<HTMLDivElement, FieldsBlockProps>((props, ref) => {
    const isSource = props.type === "SOURCE";
    const allFieldsIds: string[] = props.items.reduce((acc, item) => {
        const fieldsIds = item.fields.map(field => field.id)
        return [...acc, ...fieldsIds]
    }, [])
    const hasItems = props.items.length > 0;

    return (<Stack className='py-4 bg-black-10 flex-1 rounded-md max-h-80 overflow-hidden'>
        <Stack items="center" row className="px-4 min-h-6" space="sm">
            {!isSource && <Avatar size="xs">
                <Avatar.Fallback className='bg-green-500 text-white'>
                    V
                </Avatar.Fallback>
            </Avatar>}
            <Text size="sm" >
                {isSource ? "Items to export from mysherpas" : "Items to override in Ventures"}
            </Text>
        </Stack>
        <Stack space="sm" className='mt-1 overflow-hidden'>
            <Label className='flex items-center gap-2 px-4 min-h-6'>
                {isSource && hasItems ? <>
                    <Checkbox
                        onCheckedChange={(checked) => {
                            if (checked) {
                                props.onSelect(allFieldsIds)
                            } else {
                                props.onSelect([])
                            }
                        }}
                        checked={(() => {
                            if (allFieldsIds.length > 0 && props.selectedIds.length === allFieldsIds.length) {
                                return true
                            } else if (props.selectedIds.length === 0) {
                                return false
                            }
                            return 'indeterminate' as const

                        })()}
                    /> Select All
                </> : <div className='w-5 h-5' />}
            </Label>
            <Separator />
            <Accordion
                onValueChange={props.onValueChange}
                value={props.value}
                type="multiple"
                className='overflow-auto scrollbar-stable max-h-80'
                onScroll={props.onScroll}
                ref={ref}>
                {props.items.map(item => (<Accordion.Item
                    key={item.id}
                    value={item.id}
                    className='py-0 px-4'>
                    <Accordion.Trigger>
                        <Text
                            as="div"
                            size="sm"
                            onClick={(event) => event.stopPropagation()}
                            className='items-center gap-2 flex min-h-6 text-left items-start leading-5'>
                            {isSource ? <Checkbox
                                onCheckedChange={(checked) => {
                                    if (checked) {
                                        // only add fields that are not already selected
                                        props.onSelect([...props.selectedIds, ...item.fields.map(field => field.id).filter(id => !props.selectedIds.includes(id))])
                                    } else {
                                        props.onSelect(props.selectedIds.filter(id => !item.fields.map(field => field.id).includes(id)))
                                    }
                                }}
                                checked={(() => {
                                    if (item.fields.length > 0 && item.fields.every(field => props.selectedIds.includes(field.id))) {
                                        return true
                                    } else if (item.fields.length > 0 && item.fields.every(field => !props.selectedIds.includes(field.id))) {
                                        return false
                                    }
                                    return 'indeterminate' as const
                                })()}
                                className='mt-.5' /> : <div className='w-5' />}
                            {item.title}
                            {item.changeLevel === VentureChangeLevel.GLOBAL && <Badge
                                variant="purple" className={`shrink-0 gap-2 ${!isSource && "opacity-0 pointer-events-none"}`}>
                                Global Level
                                <Tooltip.Provider>
                                    <Tooltip>
                                        <Tooltip.Trigger>
                                            <Icon
                                                className="text-destructive"
                                                name="InfoEmpty"
                                                width={14}
                                                height={14}
                                                strokeWidth={1.5} />
                                        </Tooltip.Trigger>
                                        <Tooltip.Content
                                            align="start"
                                            side="bottom"
                                            className='max-w-xs text-xs font-normal whitespace-pre-wrap'
                                            alignOffset={0}>
                                            <strong>Warning:</strong> You will be overriding data that will affect all loans this entity is associated with.
                                        </Tooltip.Content>
                                    </Tooltip>
                                </Tooltip.Provider>
                            </Badge>}
                        </Text>
                    </Accordion.Trigger>
                    <Accordion.Content
                        className='p-0'>
                        <Stack space="md" className='ml-2 overflow-auto scrollbar-stable  min-h-48 sm:min-h-10'>
                            {item.fields.map((field) => <Stack
                                key={field.id} space="sm"
                                row
                                className='min-h-6'>
                                <Label className='items-center gap-2 flex items-start leading-5'>
                                    {isSource ? <Checkbox
                                        onCheckedChange={(checked) => {
                                            if (checked) {
                                                props.onSelect([...props.selectedIds, field.id])
                                            } else {
                                                props.onSelect(props.selectedIds.filter(id => id !== field.id))
                                            }
                                        }}
                                        checked={props.selectedIds.includes(field.id)}
                                        className='mt-.5' /> : <div className='w-5' />}
                                    {field.fieldName}
                                </Label>
                            </Stack>)}
                        </Stack>
                    </Accordion.Content>
                </Accordion.Item>))}
            </Accordion>
        </Stack>
    </Stack >)
});

VenturesDataChangedMapperFields.displayName = 'VenturesDataChangedMapperFields';