import { SherpaEntityType } from "src/backend";
import { EntityMlaFields } from "src/types/loan";

import { calculateEntityMla } from "../calculate-entity-mla";
import { calculateEntityTotalCost } from "../calculate-entity-total-cost";

const SKIP_ENTITY_TYPES = [
    SherpaEntityType.STOCK_ACCOUNT_ASSET,
    SherpaEntityType.BOND_ACCOUNT_ASSET,
    SherpaEntityType.SAVINGS_ACCOUNT_ASSET,

]

export const calculateEntityLTC = (entityFields: EntityMlaFields): number => {
    if (SKIP_ENTITY_TYPES.includes(entityFields.type)) {
        return 0;
    }

    const entityMla = calculateEntityMla(entityFields);
    const entityTotalCost = calculateEntityTotalCost(entityFields);

    if (!entityMla || !entityTotalCost) {
        return 0;
    }

    return entityMla / entityTotalCost * 100;
}
