import { IconProps } from "components/dist/atoms/Icon";
import { LoanEntityDto, SherpaEntityType } from "src/backend";

export const getEntityIcon = (args: {entity: Partial<LoanEntityDto>}): IconProps['name'] => {
   
    if (args.entity.sherpaEntity.type === SherpaEntityType.INDIVIDUAL){ 
        return "User";
    } else if (args.entity.sherpaEntity.type === SherpaEntityType.COMPANY ||
        args.entity.sherpaEntity.type === SherpaEntityType.TRUST) {
        return "Building";
    } else if (args.entity.sherpaEntity.type === SherpaEntityType.ASSET) {
        return "HomeSale";
    }

    return "Building";
}