import Avatar from 'components/dist/atoms/Avatar';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import FileIcon from 'components/dist/molecules/FileIcon';
import ScreenSizeDropdownMenu from 'components/dist/molecules/ScreenSizeDropdownMenu';
import React from "react";
import { VentureExportStatus } from 'src/backend';
import { SbaLoanGuard } from 'src/components/loans/sba-loan-guard';
import { VenturesExportStatus } from 'src/components/ventures/ventures-export-status';

import { useLoanOverviewPackage } from './loan-overview-package.hook';
import { LoanOverviewPackageProps } from './LoanOverviewPackage.types';

// the props are the return value of the useLoanOverviewPackage hook
interface LoanOverviewPackageModalsProps extends ReturnType<typeof useLoanOverviewPackage> {
    loan: LoanOverviewPackageProps['loan'];
}

export const LoanOverviewPackageDropdownItems = (props: Pick<LoanOverviewPackageModalsProps,
    'isDownloadPdfPackageDisabled' |
    'onDownloadPdfClick' |
    'isPackageExist' |
    'isVenturesEnabled' |
    'isExportToVenturesDisabled' |
    'loan' |
    'onExportToVenturesClick' |
    'isDownloadZipPackageDisabled' |
    'onDownloadZipClick'>) => {
    return <>
        <ScreenSizeDropdownMenu.Item
            disabled={props.isDownloadPdfPackageDisabled}
            onSelect={props.onDownloadPdfClick}
            className="flex flex-row gap-2">
            <FileIcon fileName="file.pdf" />
            <Text size="sm">
                {props.isPackageExist ? 'Download Package as PDF' : "Generate Package as PDF"}
            </Text>
        </ScreenSizeDropdownMenu.Item>
        <ScreenSizeDropdownMenu.Item
            disabled={props.isDownloadZipPackageDisabled}
            onSelect={props.onDownloadZipClick}
            className="flex flex-row gap-2">
            <FileIcon fileName="file.zip" />
            <Text size="sm">
                {props.isPackageExist ? 'Download Package as ZIP' : "Generate Package as ZIP"}
            </Text>
        </ScreenSizeDropdownMenu.Item>
        {props.isVenturesEnabled && <SbaLoanGuard
            loanViewType={props.loan.template?.loanViewType}>
            <ScreenSizeDropdownMenu.Item
                disabled={props.isExportToVenturesDisabled}
                onSelect={() => props.onExportToVenturesClick()}
                className="flex flex-row gap-2 items-start">
                {props.loan.ventureExportStatus === VentureExportStatus.EXPORTING
                    ? <Icon name="Refresh" width={24} height={24} className='animate-spin' />
                    : <Avatar size='xs'>
                        <Avatar.Fallback className="bg-[#40922B] text-white">
                            V
                        </Avatar.Fallback>
                    </Avatar>}
                <Stack space="sm" items="start">
                    Export to Ventures
                    <VenturesExportStatus
                        ventureExportedBy={props.loan.ventureExportedBy}
                        lastExportTime={props.loan.ventureLastExportTime}
                        exportStatus={props.loan.ventureExportStatus}
                        ventureLoanLogNumber={props.loan.ventureLoanLogNumber}
                        ventureLoanUrl={props.loan.ventureLoanUrl}
                    />
                </Stack>
            </ScreenSizeDropdownMenu.Item>
        </SbaLoanGuard>}
        {/* <ScreenSizeDropdownMenu.Item
                    onSelect={props.onExportToSharepointClick}
                    className="flex flex-row gap-2">
                    <Icon name="Refresh" width={24} height={24} />
                    <Text size="sm">
                        Export to Sharepoint
                    </Text>
                </ScreenSizeDropdownMenu.Item> */}
    </>
}
