import Text from 'components/dist/atoms/Text';
import { VentureExportStatus } from "src/backend";
import { NextLinkComposed } from "src/components/common/next-link-composed";
import { Route } from 'src/constants/ui';

interface VenturesExportingStatusProps {
    status: VentureExportStatus;
    loanShortCode: string;
    loanProjectName: string;
    loanId: string;
}

export const VenturesExportingStatus = (props: VenturesExportingStatusProps) => {


    return (
        <Text
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
            }}
            size="sm"
            className="inline cursor-pointer hover:underline"
            to={{
                pathname: Route.SINGLE_LOAN,
                query: { loanId: props.loanId }
            }}
            as={NextLinkComposed}>
            {props.status === VentureExportStatus.EXPORTING && `Exporting ${props.loanShortCode} ${props.loanProjectName} to Ventures`}
            {props.status === VentureExportStatus.EXPORT_ERROR && `Export ${props.loanShortCode} ${props.loanProjectName} to Ventures Failed`}
            {props.status === VentureExportStatus.EXPORT_COMPLETE && `Export ${props.loanShortCode} ${props.loanProjectName} to Ventures Complete`}
        </Text>
    );
}
