import React from "react";
import { LoanViewType } from "src/backend"

interface SbaLoanGuardProps {
    loanViewType: LoanViewType;
    mode?: "HIDE" | "SHOW";
}
export const SbaLoanGuard = ({ mode = "SHOW", loanViewType, children }: React.PropsWithChildren<SbaLoanGuardProps>) => {
    const isSbaLoan = [LoanViewType.SBA504, LoanViewType.SBA7A].includes(loanViewType);

    if (!isSbaLoan && mode === "SHOW") {
        return null;
    } else if (isSbaLoan && mode === "HIDE") {
        return null;
    }

    return <>{children}</>;
}