import { SherpaEntityType } from "src/backend"
import { EntityMlaFields } from "src/types/loan"

import { getEntityTotalPurchasePrice } from "../get-entity-total-purchase-price"

const CONSTRUCTION_REHAB_TYPES = [
    SherpaEntityType.CONSTRUCTION_COMMERCIAL_RE_ASSET,
    SherpaEntityType.CONSTRUCTION_RESIDENTIAL_RE_ASSET,
    SherpaEntityType.REHAB_RESIDENTIAL_RE_ASSET,
    SherpaEntityType.REHAB_COMMERCIAL_RE_ASSET
]

export const getEntityLowestAmount = (fields: EntityMlaFields): number => {
    const totalPriceOrCost: number = getEntityTotalPurchasePrice(fields)
    // calculate book value with discount
    const bookValueDiscountValue = getIntegerValue(fields.bookValue) - (getIntegerValue(fields.bookValue) * (getIntegerValue(fields.bookValueDiscount) / 100))

    const assetValues: number[] = [
        getIntegerValue(fields.appraisedValue),
        getIntegerValue(fields.afterRepairValue),
        getIntegerValue(fields.afterLeaseValue),
        bookValueDiscountValue,
        getIntegerValue(fields.asCompletedValue),
        getIntegerValue(fields.value)]

    if (!CONSTRUCTION_REHAB_TYPES.includes(fields.type)) {
        assetValues.push(totalPriceOrCost)
    }

    const nonZeroAssetValues = assetValues.filter(Boolean)

    if (nonZeroAssetValues.length === 0) {
        return 0
    }

    return Math.min(...nonZeroAssetValues.filter(Boolean))
}

const getIntegerValue = (value: string) => {
    if (!value) return 0
    return value ? parseInt(String(value).replace(/,/g, '')) : 0
}

