import { LoanStageCategory } from "src/constants/loan";
import { useDeletePhasesMutation, useGetPhasesQuery, useSetPhasesMutation } from "src/services/companyApi";
import { LoanPhaseListItem, phaseCategoriesList } from "src/slices/lists";
import { useSelector } from "src/store";
import { LoanPhaseDto } from "src/types/api";


type Props = {
    companyId: string;
}

export const useLoanStages = ({ companyId }: Props) => {
    const [setPhases] = useSetPhasesMutation();
    const [deletePhase] = useDeletePhasesMutation();
    const { data } = useGetPhasesQuery({
        companyId: companyId
    }, {
        skip: !companyId
    });

    const phases = data || [];
    const originationPhases = phases.filter((stage: LoanPhaseDto) => stage.category === LoanStageCategory.ORIGINATION)
    const leadPhases = phases.filter((stage: LoanPhaseDto) => stage.category === LoanStageCategory.LEAD)
    const archivePhases = phases.filter((stage: LoanPhaseDto) => stage.category === LoanStageCategory.ARCHIVE)
    const portfolioPhases = phases.filter((stage: LoanPhaseDto) => stage.category === LoanStageCategory.PORTFOLIO)

    const categories: LoanPhaseListItem[] = useSelector(phaseCategoriesList);

    const handleDelete = (id: string) => {
        const phase = phases.find(s => s.id === id);
        deletePhase([phase])
    }

    const handleSave = (newPhases: LoanPhaseDto[]) => {
        // update existing phases from loanStages
        const updatedStages = phases.map((existingPhase) => {
            const updatedStage = newPhases.find((loanStage) => loanStage.id === existingPhase.id);
            return updatedStage || existingPhase;
        });
        // if new phases are added, add them to the end of the list
        const createdPhases = newPhases.filter((newPhase) => !newPhase.id);
        const newLoanStages = [...updatedStages, ...createdPhases];
        setPhases(newLoanStages).unwrap();
    }

    const firstPortfolioPhase = portfolioPhases?.[0];
    const firstArchivePhase = archivePhases?.[0];
    const firstLeadPhase = leadPhases?.[0];

    return {
        phases,
        originationPhases,
        archivePhases,
        portfolioPhases,
        leadPhases,
        categories,
        firstPortfolioPhase,
        firstArchivePhase,
        firstLeadPhase,
        onDelete: handleDelete,
        onSave: handleSave,
    } as const
}