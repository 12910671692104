import Accordion from 'components/dist/atoms/Accordion';
import Badge from 'components/dist/atoms/Badge';
import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Select from 'components/dist/atoms/Select';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { LoanEntityDto, LoanEntityLabel, SherpaEntityType } from 'src/backend';
import { LabelChip } from 'src/components/user/label-chip';
import { useAddLabelsToEntityMutation } from 'src/services/entityApi';
import { GuaranteeType } from 'src/types/entity';

interface VenturesEntitiesSetupProps {
    entities: LoanEntityDto[];
    loanId: string;
    onSuccess: () => void;
    onCancel: () => void;
}

const typeLabel = {
    [SherpaEntityType.TRUST]: "Trusts",
    [SherpaEntityType.COMPANY]: "Companies",
}

const SortedGuaranteeTypes = Object.keys(GuaranteeType).sort();
const GuaranteeTypeLabels = Object.values(GuaranteeType);
export const VenturesEntitiesSetup = (props: VenturesEntitiesSetupProps) => {
    const [addLabelsToEntity, { isLoading }] = useAddLabelsToEntityMutation();
    const [entityFields, setEntityFields] = React.useState<Record<string, { guaranteeType: LoanEntityLabel }>>({});

    const entitiesGroupedByType = props.entities?.reduce((acc, entity) => {
        if (!acc[typeLabel[entity.sherpaEntity.type]]) {
            acc[typeLabel[entity.sherpaEntity.type]] = [];
        }
        acc[typeLabel[entity.sherpaEntity.type]].push(entity);
        return acc;
    }, {} as Record<string, LoanEntityDto[]>);

    const entitiesGroupedByTypeArray = Object.entries(entitiesGroupedByType);

    useEffect(function mapEntityToFields() {
        const data = props.entities.reduce((acc, entity) => {
            const guaranteeType = entity.label.find(entityLabel => SortedGuaranteeTypes.includes(entityLabel));
            acc[entity.sherpaEntity.id] = {
                guaranteeType: guaranteeType,
            }
            return acc;
        }, {} as Record<string, { guaranteeType: LoanEntityLabel }>);
        setEntityFields(data);
    }, [props.entities])

    const onContinueClick = async () => {
        if (isLoading) {
            toast.error("Please wait for the current operation to complete");
            return;
        }
        try {
            const isValid = Object
                .values(entityFields)
                .every(({ guaranteeType }) => guaranteeType);
            if (!isValid) {
                toast.error("Please complete setup for all entities before continuing to export");
            } else {
                const entityUserLabelsMap = props.entities.reduce((acc, entity) => {
                    acc[entity.sherpaEntity.id] = entity.userLabels.map(label => label.id);
                    return acc;
                }, {} as Record<string, string[]>);
                const entityLabelMap = props.entities.reduce((acc, entity) => {
                    acc[entity.sherpaEntity.id] = entity.label;
                    return acc;
                }, {} as Record<string, LoanEntityLabel[]>);
                const promises = Object
                    .entries(entityFields)
                    .map(([sherpaEntityId, { guaranteeType }]) => {
                        const labels = [
                            ...entityLabelMap[sherpaEntityId] ?? [],
                        ].filter(label => !GuaranteeTypeLabels.includes(label));
                        return addLabelsToEntity({
                            sherpaEntityId,
                            loanId: props.loanId,
                            label: [
                                ...labels,
                                guaranteeType
                            ],
                            userLabels: entityUserLabelsMap[sherpaEntityId] ?? []
                        })
                    });
                await Promise.all(promises);
                props.onSuccess();
            }
        } catch {
            toast.error("An error occurred while setting up entities");
        }
    }
    return <Stack space="md">
        <Stack space="md" className='px-4 pt-4' row>
            <Icon name="BigExclamation" width={25} height={25} className="shrink-0 text-black-10" />
            <Stack space="sm">
                <Text size="sm" weight="medium">Complete Entity Setup</Text>
                <Text size="sm">These entities have not been setup. Please complete setup before continuing to export</Text>
            </Stack>
        </Stack>
        <Separator />
        <Stack space="none" className='py-4 overflow-auto scrollbar-stable'>
            {entitiesGroupedByTypeArray.map(([title, items]) => <Accordion
                key={title}
                className='bg-black-10 sm:bg-white rounded-md px-4 pb-4'
                defaultValue={["Companies", 'Trusts']}
                type="multiple">
                <Accordion.Item value={title}>
                    <Accordion.Trigger className='text-sm font-normal cursor-pointer' asChild>
                        <Stack space="sm" row>
                            <Badge
                                className='w-6 h-6 aspect-square shrink-0 items-center justify-center'
                                variant="secondary">
                                {items.length}
                            </Badge>
                            {title}
                        </Stack>
                    </Accordion.Trigger>
                    <Separator />
                    <Accordion.Content>
                        {items.map((entity) => <Stack
                            key={entity.id}
                            space="sm"
                            className='px-4 py-2 sm:flex-row'>
                            <Stack space="sm" row className='flex-1 md:flex-2 items-center'>
                                <Icon name={entity.sherpaEntity.type === "COMPANY" ? "Building" : "HomeSale"} className='shrink-0' width={20} height={20} strokeWidth={1.5} />
                                <Text size="sm" weight="medium">{entity.sherpaEntity.name}</Text>
                                {entity.label.includes(LoanEntityLabel.PRIMARY_OPERATION_COMPANY) && <LabelChip className='shrink-0' label="POC" />}
                            </Stack>
                            <Stack
                                className='flex-1 sm:flex-row'
                                space="sm">
                                <div className='flex-1 overflow-hidden'>
                                    <Select
                                        onValueChange={(guaranteeType: LoanEntityLabel) => {
                                            setEntityFields(prevState => ({
                                                ...prevState,
                                                [entity.sherpaEntity.id]: {
                                                    ...prevState[entity.sherpaEntity.id],
                                                    guaranteeType
                                                }
                                            }))
                                        }}
                                        defaultValue={entityFields[entity.sherpaEntity.id]?.guaranteeType ?? ""}
                                    >
                                        <Select.Trigger
                                            aria-label="Guarantee Type"
                                            className='bg-white capitalize flex-1 h-8'>
                                            <Select.Value placeholder="Guarantee Type" />
                                        </Select.Trigger>
                                        <Select.Content className='z-splash'>
                                            {SortedGuaranteeTypes.map((label) => <Select.Item
                                                className='capitalize'
                                                key={label}
                                                value={label}>
                                                {label.replace(/_/g, ' ').toLowerCase()}
                                            </Select.Item>)}
                                        </Select.Content>
                                    </Select>
                                </div>
                            </Stack>
                        </Stack>)}
                    </Accordion.Content>
                </Accordion.Item>
            </Accordion>)}
        </Stack>
        <Separator />
        <Stack space="sm" row className='justify-end sm:pb-4 px-4'>
            <Button
                onClick={props.onCancel}
                variant="outline">
                Cancel
            </Button>
            <Button
                loading={isLoading}
                onClick={onContinueClick}>
                Continue to Export
            </Button>
        </Stack>
    </Stack>
}