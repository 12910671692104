import { styled } from '@mui/material/styles';
import { LoanReviewStatus } from "src/backend"
import { themeV2 } from "src/theme/mysherpas-theme-option"

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: white;
    padding: 3px;
    &[data-status="${LoanReviewStatus.READY_FOR_REVIEW_PENDING}"],
    &[data-status="${LoanReviewStatus.HOLD}"],
    &[data-status="${LoanReviewStatus.WITHDRAWN}"] {
        background-color: ${themeV2.neutral.grey[8]};
        color: ${themeV2.colors.black.neutral};
    }
    &[data-status="${LoanReviewStatus.READY_FOR_REVIEW_REVIEW}"] {
      background-color: ${themeV2.colors.blue[100]};
    }
    &[data-status="${LoanReviewStatus.IN_SERVICE}"] {
      background-color: ${themeV2.colors.yellow[200]};
      color: ${themeV2.colors.black.neutral};
    }
    &[data-status="${LoanReviewStatus.IN_PROGRESS}"] {
      background-color: ${themeV2.colors.blue[50]};
      color: white;
    }
    &[data-status="${LoanReviewStatus.ARCHIVE}"] {
      background-color: ${themeV2.colors.black.neutral};
    }
    &[data-status="${LoanReviewStatus.REVIEW_COMPLETE_ACCEPTED}"] {
            background-color: ${themeV2.colors.green[50]};
    }
    &[data-status="${LoanReviewStatus.REVIEW_COMPLETE_CONDITIONAL}"],
    &[data-status="${LoanReviewStatus.LEAD}"] {
            background-color: ${themeV2.colors.yellow[100]};
            color: ${themeV2.colors.black.neutral};
    }
    &[data-status="${LoanReviewStatus.REVIEW_COMPLETE_REJECTED}"],
    &[data-status="${LoanReviewStatus.IN_DEFAULT}"] {
            background-color: ${themeV2.colors.red[50]};
    }
    &[data-status="${LoanReviewStatus.READY_FOR_UNDERWRITING}"] {
            background-color: ${themeV2.colors.purple[200]};
    }
    &[data-status="${LoanReviewStatus.CLOSING}"] {
            background-color: ${themeV2.colors.blue[20]};
            color: ${themeV2.colors.black.neutral};
    }
`

export const LoanReviewIconStyles = {
        Wrapper
}